import React, { useEffect, useState, useRef } from 'react'
import BottomBar from '../Components/BottomBar'
import Header from '../Components/Header'
import SideTab from '../Components/SideTab'
import Footer from '../Components/Footer'
import { Link, useParams } from 'react-router-dom'
import { FaAngleDown, FaAngleLeft } from "react-icons/fa6";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Referralmodal from '../Modals/Referralmodal'
import { getReferralReports, getReferredUserForTree } from '../actions/axioss/user.axios'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { address_showing } from '../actions/common'
import CopyToClipboard from 'react-copy-to-clipboard'

function RefferalTree() {
    const { payload } = useSelector((state) => state.LoginReducer.User);
    const footerRef = useRef(null);
    const [isFixed, setIsFixed] = useState(true);

    const [refferalChild, setRefferalChild] = useState([]);

    const handleScroll = () => {

        const footerTop = footerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (footerTop < windowHeight) {
            setIsFixed(false);


        } else {
            setIsFixed(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if (payload?.WalletAddress) {
            fetchReferralTree()
        }
    }, [payload])


    const fetchReferralTree = async () => {
        const getRep = await getReferredUserForTree({ walletAddress: payload?.WalletAddress });
        console.log('getRepgetRep---->', getRep);
        setRefferalChild(getRep?.data)
    }

    console.log('refferalChild---->', refferalChild);

    return (
        <>
            <BottomBar />
            <Header />

            <div className="innercontent referral_report">
                <div className={isFixed ? "side_left fixed" : "side_left sticky"}  >
                    <SideTab />
                </div>
                <div className="banner_section">
                    <div className="inner-container__width">

                        <Link to={`/profile/${payload?.WalletAddress}`} className="back_btn"><FaAngleLeft /> Back</Link>
                        <div className='referral_tree'>
                            <div className='referral_tree-header'>
                                <p className='title'>Refferals</p>
                            </div>
                            <div className={`${refferalChild?.length <= 1 ? "justifyContentCenterMob" : ""} ${refferalChild?.length <= 3 ? "justifyContentCenter" : "justifyContentStart"} refferral_tree-body`} >
                                {refferalChild?.length != 0 ?
                                    refferalChild?.map((item) => (
                                        <div className='refferral_tree-children' key={item?.id}>
                                            <div className='refferral_tree-children--content'>
                                                <p><span>Wallet :</span>{" "}{address_showing(item?.WalletAddress)}</p>
                                                {item?.EmailId && <p><span>Email :</span>{" "}{item?.EmailId}</p>}
                                            </div>
                                            <div className='refferral_tree-children-border--left'></div>
                                            <div className='refferral_tree-children-border--center'></div>
                                            <div className='refferral_tree-children-border--right'></div>

                                        </div>
                                    )) :
                                    <div className='refferral_tree-children' >
                                        <div className='refferral_tree-children--content'>
                                            <p className='text-center'>No data found</p>
                                        </div>
                                        <div className='refferral_tree-children-border--left'></div>
                                        <div className='refferral_tree-children-border--center'></div>
                                        <div className='refferral_tree-children-border--right'></div>

                                    </div>
                                }


                            </div>
                        </div>

                    </div>

                </div>


            </div>

            <div ref={footerRef}>
                <Footer />
            </div>
        </>
    )
}

export default RefferalTree